import React from 'react';
import StyledWrapper from './styled';

function NoteForm(props) {
  return (
    <StyledWrapper>
      <div className="note-wrapper">
        <p className="note-text">{props.text}</p>
      </div>
    </StyledWrapper>
  );
}

export default NoteForm;