import styled from 'styled-components';

export default styled.div`
  margin-bottom: 15px;
  .main-input {
    display: flex;
    width: 100%;
    height: 35px;
    border: 1px #DADADA solid;
    background-color: ${props => props.disabled ? '#f5f5f5' : '#fff'};
    outline: none;
    padding: 4px 11px;
    color: #000;
  }
  .input-label {
    color: #c0c0c0;
  }
  .error-meassage {
    color: red;
    font-size: 12px;
  }
  .select-field {
    height: 35px;
    width: 100%;
    .ant-select-selection--single {
      height: 35px;
    }
  }
  .flag-dropdown {
    height: 35px;
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
  }
  .react-tel-input input[type=tel] {
    width: 100%;
    height: 35px;
    color: #000;
    border: 1px solid #d9d9d9;
  }
  .text-area {
    height: auto;
  }
`;
