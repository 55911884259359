import styled from "styled-components";

export default styled.div`
  .margin-bottom-15 {
    margin-bottom: 15px;
  }
  .form-wrapper {
    margin-top: 20px;
  }
  .row-detail {
    margin-top: 20px;
  }
  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-success {
    margin-right: 20px;
  }
  @media only screen and (max-width: 576px) {
    .buttons-wrapper {
      display: block;
      padding: 0 12px;
    }
    .btn-success {
      margin-right: 0;
      width: 100%;
    }
  }
`;
