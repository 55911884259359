import { BookingEnquiry, BookingSuccess, BookingConfirmation, PaymentSuccessful} from './pages';

const routes = [
  {
    title: 'Homepage',
    to: '/',
    component: BookingEnquiry,
    exact: true,
    visible: true
  },
  {
    title: 'Booking Enquiry',
    to: '/booking-enquiry',
    component: BookingEnquiry,
    exact: true,
    visible: true
  },
  {
    title: 'Booking Success',
    to: '/booking-success/:data?',
    component: BookingSuccess,
    exact: true,
    visible: true
  },
  {
    title: 'Booking Confirmation',
    to: '/booking-confirmation/:data?',
    component: BookingConfirmation,
    exact: true,
    visible: true
  },
  {
    title: 'Payment Successful',
    to: '/payment-successful',
    component: PaymentSuccessful,
    exact: true,
    visible: true
  },
]

export default routes;
