import axios from "axios";

const sendmail = async (url, body) => {
  try {
    const results = await axios.post(url, body);
    // if (results.data.status === "success") {
    //   console.log("mail sent");
    // }
  } catch (error) {
    console.log("send mail error", { error });
  }
};
export default sendmail;
