import React, { useState, useEffect } from "react";
import { Form, Row, Col, Alert, Result, Modal } from "antd";
import _ from "lodash";
import CryptoJS from "crypto-js";
import queryString from "query-string";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import StyledWrapper from "./styled";

import { NoteForm, DefaultButton, InputField } from "../../components";
import validateForm from "../../utils/validateForm";

function BookingEnquiry(props) {
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [fetchData, setFetchData] = useState("");
  const [saleData, setSaleData] = useState("");
  const [fetchObjective, setFetchObjective] = useState("");
  const [valueObjective, setValueObjective] = useState();
  const [valueFee, setValueFee] = useState("");
  const [valueFirstname, setValueFirstname] = useState("");
  const [valueLastname, setValueLastname] = useState("");
  const [valueCountryCode, setValueCountryCode] = useState("+66");
  const [valuePhone, setValuePhone] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valueSalesRemark, setValueSalesRemark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState("success");

  const searchParam = queryString.parse(props.location.search);
  const itemCode = _.get(searchParam, "itemCode");
  const paramToken = _.get(searchParam, "token");

  const fetchToken = async token => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_HOST_PORT}/verifytoken`,
        {
          Headers: { "Content-Type": "application/json" },
          "token-client": token
        }
      );
      // console.log("fetchToken result:: ", result);
      setSaleData(_.get(result, "data.sale_data"));
      return {
        status: true,
        result
      };
    } catch (error) {
      console.log("error token", { error });
      return {
        status: false,
        error: { error }
      };
    }
  };

  useEffect(() => {
    _.isEmpty(props.location.search) &&
      window.setTimeout(() => {
        setIsLoadingScreen(false);
      }, 1000);

    if (paramToken && process.env.REACT_APP_SECRET_KEY) {
      const ciphertext = CryptoJS.AES.encrypt(
        paramToken,
        process.env.REACT_APP_SECRET_KEY
      );
      const ciphertextEncrypt = ciphertext.toString();
      // console.log(ciphertextEncrypt);
      Promise.all([fetchToken(ciphertextEncrypt)])
        .then(results => {
          const result = _.head(results);
          if (_.get(result, "status")) {
            setVerifyStatus(_.get(result, "data.message"));
            setIsLoadingScreen(false);
            // setIsFull(false);
          } else {
            setVerifyStatus("error");
            setIsLoadingScreen(false);
          }
        })
        .catch(err => {
          console.log({ err });
          setVerifyStatus("error");
          setIsLoadingScreen(false);
        });
    }

    if (!_.isEmpty(itemCode)) {
      const fetchData = async () => {
        try {
          const result = await axios.get(
            `${process.env.REACT_APP_HOST_PORT}/properties/${itemCode}`
          );
          setFetchData(_.get(result, "data.result"));
          setValueObjective(
            _.get(result, "data.result.RequirementTypeCode") === "RT002" ? 2 : 1
          );
        } catch (error) {
          console.log("fetch error", { error });
          if (error.response.data.err === "Item already booked!!") {
            setIsFull(true);
          }
        }
        setIsLoadingScreen(false);
      };
      fetchData();
    }

    const fetchObjective = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_HOST_PORT}/customer-objective`
        );
        setFetchObjective(result.data.results);
      } catch (error) {
        console.log("fetch error", error.response.data.err);
      }
    };
    fetchObjective();
  }, []);

  // const objective =
  //   _.get(fetchData, "RequirementTypeCode") === "RT002"
  //     ? "Want to rent"
  //     : "Want to buy";

  const fields = {
    saleCode: {
      type: "normal",
      label: "Sales code",
      value: _.get(saleData, "sale_code"),
      disabled: true
    },
    itemCode: {
      type: "normal",
      label: "Item code",
      value: itemCode,
      disabled: true
    },
    projectName: {
      type: "normal",
      label: "Project name",
      value: _.get(fetchData, "ProjectName"),
      disabled: true
    },
    itemUnitNo: {
      type: "normal",
      label: "Item unit number",
      value: _.get(fetchData, "ItemUnitNo"),
      disabled: true
    },
    itemFloor: {
      type: "normal",
      label: "Floor",
      value: _.get(fetchData, "ItemFloor"),
      disabled: true
    },
    ItemHomeNo: {
      type: "normal",
      label: "House number",
      value: _.get(fetchData, "ItemHomeNo"),
      disabled: true
    },
    CustomerObjective: {
      type: "select",
      label: "Customer objective",
      value: valueObjective,
      options: fetchObjective
    },
    BookingFee: {
      type: "normal",
      label: "Booking fee",
      value: valueFee,
      rules: [{ required: true, message: "Please input your booking fee!" }],
      blur: true,
      focus: true
    },
    Firstname: {
      type: "normal",
      label: "Firstname",
      value: valueFirstname,
      rules: [{ required: true, message: "Please input your firstname!" }]
    },
    Lastname: {
      type: "normal",
      label: "Lastname",
      value: valueLastname,
      rules: [{ required: true, message: "Please input your lastname!" }]
    },
    Mobile: {
      type: "phone",
      label: "Mobile",
      value: valuePhone,
      rules: [{ required: true, message: "Please input your mobile!" }]
    },
    Email: {
      type: "email",
      label: "Email",
      value: valueEmail,
      rules: [
        {
          regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          message: "Wrong email format!"
        }
      ]
    },
    SalesRemark: {
      type: "multiline",
      label: "Sales Remark",
      value: valueSalesRemark
    }
  };

  const handleSubmit = e => {
    const bodyFetch = {
      Headers: {
        "Content-Type": "application/json"
      },
      sale_code: _.get(saleData, "sale_code"),
      sale_firstname: _.get(saleData, "firstname"),
      sale_lastname: _.get(saleData, "lastname"),
      item_code: itemCode,
      project_name: _.get(fetchData, "ProjectName"),
      project_code: _.get(fetchData, "ProjectCode"),
      item_unit_no: _.get(fetchData, "ItemUnitNo"),
      floor: _.get(fetchData, "ItemFloor"),
      house_no: _.get(fetchData, "ItemHomeNo"),
      customer_objective: _.toNumber(valueObjective),
      booking_fee: _.toNumber(_.replace(valueFee, /[,]/g, "")),
      firstname: valueFirstname,
      lastname: valueLastname,
      country_code: valueCountryCode,
      phone_no: valueCountryCode === "+66" ? `0${valuePhone}` : valuePhone,
      email: valueEmail,
      sale_remark: valueSalesRemark
    };
    console.log(bodyFetch);
    e.preventDefault();
    setIsLoading(true);
    setIsValidate(true);
    window.setTimeout(() => {
      if (
        !_.isEmpty(valueFee) &&
        !_.isEmpty(valueFirstname) &&
        !_.isEmpty(valueLastname) &&
        !_.isEmpty(valuePhone)
      ) {
        setIsLoading(false);
        const fetchData = async () => {
          try {
            const order = await axios.post(
              `${process.env.REACT_APP_HOST_PORT}/orders/create`,
              bodyFetch
            );
            if (paramToken && process.env.REACT_APP_SECRET_KEY) {
              const ciphertext = CryptoJS.AES.encrypt(
                paramToken,
                process.env.REACT_APP_SECRET_KEY
              );
              const ciphertextEncrypt = ciphertext.toString();
              Promise.all([fetchToken(ciphertextEncrypt)])
                .then(results => {
                  const result = _.head(results);
                  if (_.get(result, "status")) {
                    if (_.get(order, "data.message") === "createOrder") {
                      props.history.push(
                        `/booking-success/?orderId=${_.get(
                          order,
                          "data.orderId"
                        )}&token=${paramToken}`
                      );
                    } else {
                      e.preventDefault();
                    }
                  } else {
                    e.preventDefault();
                    Modal.info({
                      title: "Session expired, please login again",
                      onOk() {
                        window.location.replace(
                          `https://www.theagent.co.th/property/d/${itemCode}`
                        );
                      }
                    });
                  }
                })
                .catch(err => {
                  console.log("err catch", { err });
                });
            }
          } catch (error) {
            console.log("error create order", { error });
            // if (error.response.data.status === "error") {
            //   setIsFull(true);
            // }
            // if (error.response.data.err.code === "auth/argument-error") {
            //   e.preventDefault();
            //   Modal.info({
            //     title: "Session expired, please login again",
            //     onOk() {
            //       window.location.replace(
            //         `https://www.theagent.co.th/property/d/${itemCode}`
            //       );
            //     }
            //   });
            // }
            return {
              result: {
                status: "error"
              }
            };
          }
        };
        fetchData();
      } else {
        setIsLoading(false);
      }
    }, 1000);
  };

  const localStringToNumber = s => {
    return _.toNumber(_.replace(_.toString(s), /[^0-9.-]+/g, ""));
  };

  const handleFocusFee = e => {
    localStringToNumber(e.target.value);
  };

  const handleBlurFee = e => {
    const value = localStringToNumber(e.target.value).toLocaleString(
      undefined,
      { minimumIntegerDigits: 1 }
    );
    setValueFee(value);
  };

  const handlePhoneNumber = (value, data) => {
    setValueCountryCode(`+${_.get(data, "dialCode")}`);
    setValuePhone(_.replace(value, /[^0-9]+/g, "").slice(data.dialCode.length));
  };

  const handleSelect = value => {
    setValueObjective(_.toNumber(value));
  };

  const onValueChange = (e, name) => {
    if (name === "Booking fee") {
      setValueFee(e.target.value.replace(/\D/, ""));
    }
    if (name === "Firstname") {
      setValueFirstname(e.target.value);
    }
    if (name === "Lastname") {
      setValueLastname(e.target.value);
    }
    if (name === "Email") {
      setValueEmail(e.target.value);
    }
    if (name === "Sales Remark") {
      setValueSalesRemark(e.target.value);
    }
  };

  const renderField = field => {
    const { status, message } = validateForm(
      _.get(field, "value"),
      _.get(field, "rules")
    );
    return (
      <InputField
        label={_.get(field, "label")}
        type={_.get(field, "type")}
        message={message}
        value={_.get(field, "value")}
        onChange={
          _.get(field, "type") === "phone"
            ? handlePhoneNumber
            : _.get(field, "type") === "select"
            ? handleSelect
            : e => onValueChange(e, _.get(field, "label"))
        }
        onBlur={_.get(field, "blur") && (e => handleBlurFee(e))}
        onFocus={_.get(field, "focus") && (e => handleFocusFee(e))}
        disabled={_.get(field, "disabled")}
        options={_.get(field, "options")}
        isValidate={isValidate}
      />
    );
  };

  return (
    <LoadingScreen
      loading={isLoadingScreen}
      bgColor="#f5f5f5"
      spinnerColor="#EC6219"
    >
      <StyledWrapper>
        {verifyStatus === "error" ||
        _.isUndefined(paramToken) ||
        _.isUndefined(itemCode) ||
        _.isEmpty(paramToken) ||
        _.isEmpty(itemCode) ? (
          <div className="token-error">
            <Result
              status="error"
              title="Permission Denied"
              subTitle="Please make sure you have the correct access login as user and try again."
            />
          </div>
        ) : (
          <div className="booking-field-wrapper">
            <h1 className="page-name">Booking Enquiry</h1>
            {_.isUndefined(fetchData) && (
              <Alert
                message="Cannot load this item code"
                description="Please check your item code again"
                type="error"
                showIcon
              />
            )}
            {isFull && (
              <Alert
                message="This item is already booked !!"
                description="Please check your item code again"
                type="error"
                showIcon
              />
            )}
            {isFull === false && (
              <Form onSubmit={handleSubmit} className="form-wrapper">
                <Row
                  gutter={24}
                  className="row-detail"
                  type="flex"
                  justify="center"
                  align="top"
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Sales code</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "saleCode", "Fern Mock 111"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Item code</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "itemCode"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Project name</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "projectName"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Item unit number</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "itemUnitNo"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Floor</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "itemFloor"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>House number</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "ItemHomeNo"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Customer Objective*</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "CustomerObjective"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Booking fee*</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "BookingFee"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Firstname*</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "Firstname"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Lastname*</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "Lastname"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Mobile*</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "Mobile"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Email</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "Email"))}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={4}
                    className="margin-bottom-15"
                  >
                    <label>Sales Remark</label>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    {renderField(_.get(fields, "SalesRemark"))}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {!_.isUndefined(fetchData) && (
                      <NoteForm
                        text={`${
                          isFull
                            ? "ไม่สามารถทำการจองได้\nเนื่องจากห้องนี้ถูกจองโดยลูกค้าท่านอื่นไปแล้ว"
                            : `ตรวจสอบข้อมูลการจองให้ถูกต้อง\nหากยืนยันแล้วจะไม่สามารถแก้ไขข้อมูลได้`
                        }`}
                      />
                    )}
                  </Col>
                  <div className="button-wrapper-center">
                    <DefaultButton
                      text="Book"
                      htmlType="submit"
                      loading={_.toString(isLoading)}
                      disabled={_.isUndefined(fetchData) ? true : false}
                    />
                  </div>
                </Row>
              </Form>
            )}
          </div>
        )}
      </StyledWrapper>
    </LoadingScreen>
  );
}

export default BookingEnquiry;
