import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Row, Col, Result, Modal } from "antd";
import queryString from "query-string";
import LoadingScreen from "react-loading-screen";
import CryptoJS from "crypto-js";
import axios from "axios";
import StyledWrapper from "./styled";

import { NoteForm, DefaultButton, InputField } from "../../components";
import bookingFeeValue from "../../utils/bookingFeeValue";

function BookingSuccess(props) {
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [fetchData, setFetchData] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState("success");

  const searchParam = queryString.parse(props.location.search);
  const orderId = _.get(searchParam, "orderId");
  const paramToken = _.get(searchParam, "token");

  useEffect(() => {
    _.isEmpty(props.location.search) &&
      window.setTimeout(() => {
        setIsLoadingScreen(false);
      }, 1000);

    const fetchToken = async token => {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_HOST_PORT}/verifytoken`,
          {
            Headers: { "Content-Type": "application/json" },
            "token-client": token
          }
        );
        if (result) {
          setVerifyStatus(result.data.message);
        } else {
          console.log("else");
        }
      } catch (error) {
        console.log("error token", { error });
        setVerifyStatus("error");
      }
      setIsLoadingScreen(false);
    };

    if (paramToken && process.env.REACT_APP_SECRET_KEY) {
      // console.log('env ', process.env.REACT_APP_SECRET_KEY);
      const ciphertext = CryptoJS.AES.encrypt(
        paramToken,
        process.env.REACT_APP_SECRET_KEY
      );
      const ciphertextEncrypt = ciphertext.toString();
      // console.log('ciphertextEncrypt--> ', ciphertextEncrypt);
      fetchToken(ciphertextEncrypt);
    }

    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_HOST_PORT}/orders/${orderId}`
        );
        setFetchData(result.data.result);
      } catch (error) {
        console.log("fetch error", { error });
      }
      setIsLoadingScreen(false);
    };
    fetchData();
  }, []);

  const objective =
    _.get(fetchData, "customerObjective") === 2
      ? "Want to rent"
      : "Want to buy";
  const bookingFee = bookingFeeValue(_.get(fetchData, "bookingFee"));

  const fields = {
    saleCode: {
      type: "normal",
      label: "Sales code",
      value: _.get(fetchData, "saleCode"),
      disabled: true
    },
    itemCode: {
      type: "normal",
      label: "Item code",
      value: _.get(fetchData, "itemCode"),
      disabled: true
    },
    projectName: {
      type: "normal",
      label: "Project name",
      value: _.get(fetchData, "projectName"),
      disabled: true
    },
    itemUnitNo: {
      type: "normal",
      label: "Item unit number",
      value: _.get(fetchData, "itemUnitNumber"),
      disabled: true
    },
    itemFloor: {
      type: "normal",
      label: "Floor",
      value: _.get(fetchData, "itemFloor"),
      disabled: true
    },
    ItemHomeNo: {
      type: "normal",
      label: "House number",
      value: _.get(fetchData, "itemHouseNumber"),
      disabled: true
    },
    CustomerObjective: {
      type: "normal",
      label: "Customer objective",
      value: objective,
      disabled: true
    },
    BookingFee: {
      type: "normal",
      label: "Booking fee",
      value: bookingFee,
      disabled: true
    },
    Firstname: {
      type: "normal",
      label: "Firstname",
      value: _.get(fetchData, "customerFirstname"),
      disabled: true
    },
    Lastname: {
      type: "normal",
      label: "Lastname",
      value: _.get(fetchData, "customerLastname"),
      disabled: true
    },
    Mobile: {
      type: "normal",
      label: "Mobile",
      value:
        _.get(fetchData, "customerCountryCode") === "+66"
          ? `${_.get(fetchData, "customerMobileNumber")}`
          : `${_.get(fetchData, "customerCountryCode")}${_.get(
              fetchData,
              "customerMobileNumber"
            )}`,
      disabled: true
    },
    Email: {
      type: "email",
      label: "Email",
      value: _.get(fetchData, "customerEmail"),
      disabled: true
    },
    SalesRemark: {
      type: "normal",
      label: "Sales Remark",
      value: _.get(fetchData, "salesRemark"),
      disabled: true
    }
  };

  const bodyEmail = {
    "project-name": _.get(fetchData, "projectName"),
    "mail-to": _.get(fetchData, "customerEmail"),
    "url-payment": `https://the-agent-payment-gateway.netlify.com/booking-confirmation/?orderId=${orderId}`
  };

  const handleClickToConfirmation = () => {
    props.history.push(`/booking-confirmation/?orderId=${orderId}`);
  };

  const handleClickEmail = e => {
    e.preventDefault();
    const sendEmail = async () => {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_HOST_PORT}/sendmail`,
          bodyEmail
        );
        if (result.data.message === "success") {
          Modal.success({
            title: "ส่งอีเมลล์สำเร็จ",
            content: "กรุณาตรวจสอบในกล่องจดหมาย",
            onOk() {
              window.location.replace(
                `https://www.theagent.co.th/property/d/${_.get(
                  fetchData,
                  "itemCode"
                )}`
              );
            }
          });
        }
      } catch (error) {
        console.log("error email", { error });
      }
    };
    sendEmail();
  };

  const handleClickCopyLink = () => {
    const el = document.createElement("input");
    el.value = `https://the-agent-payment-gateway.netlify.com/booking-confirmation/?orderId=${orderId}`;
    el.id = "url-input";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    el.remove();
    setIsCopied(true);
    window.setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const renderField = field => {
    return (
      <InputField
        label={_.get(field, "label")}
        type={_.get(field, "type")}
        value={_.get(field, "value")}
        disabled={_.get(field, "disabled")}
      />
    );
  };

  return (
    <LoadingScreen
      loading={isLoadingScreen}
      bgColor="#f5f5f5"
      spinnerColor="#EC6219"
    >
      <StyledWrapper>
        {verifyStatus === "error" ||
        _.isUndefined(paramToken) ||
        _.isUndefined(orderId) ||
        _.isEmpty(paramToken) ||
        _.isEmpty(orderId) ? (
          <div className="token-error">
            <Result
              status="error"
              title="Permission Denied"
              subTitle="Please make sure you have the correct access login as user and try again."
            />
          </div>
        ) : (
          <div className="booking-success-wrapper">
            <h1 className="page-name">Booking Enquiry</h1>
            <Row
              gutter={24}
              className="row-detail"
              type="flex"
              justify="center"
              align="top"
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Sales code</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "saleCode", "Fern Mock 111"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Item code</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "itemCode"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Project name</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "projectName"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Item unit number</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "itemUnitNo"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Floor</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "itemFloor"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>House number</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "ItemHomeNo"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Customer Objective*</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "CustomerObjective"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Booking fee*</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "BookingFee"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Firstname*</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "Firstname"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Lastname*</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "Lastname"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Mobile*</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "Mobile"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Email</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "Email"))}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={4}
                className="margin-bottom-15"
              >
                <label>Sales Remark</label>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                {renderField(_.get(fields, "SalesRemark"))}
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <NoteForm text="ทำการจองเรียบร้อยแล้ว" />
              </Col>
              <div className="buttons-wrapper">
                <DefaultButton
                  text="Payment"
                  htmlType="submit"
                  className="btn-success"
                  buttonClicked={handleClickToConfirmation}
                />
                {!_.isEmpty(_.get(fetchData, "customerEmail")) && (
                  <DefaultButton
                    text="Email"
                    className="btn-success"
                    buttonClicked={handleClickEmail}
                  />
                )}
                <DefaultButton
                  text="Copy link"
                  className="btn-success"
                  buttonClicked={handleClickCopyLink}
                  copied={isCopied}
                />
              </div>
            </Row>
          </div>
        )}
      </StyledWrapper>
    </LoadingScreen>
  );
}

export default BookingSuccess;
