import styled from 'styled-components';

export default styled.div`
  .note-wrapper {
    background-color: #494949;
    color: #fff;
    padding: 10px;
    text-align: center;
    white-space: pre-wrap;
    margin-top: 20px;
    .note-text {
      margin-bottom: 0;
    }
  }
`;