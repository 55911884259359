import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Row, Col, Form, Button, Modal, Result } from "antd";
import queryString from "query-string";
import axios from "axios";
import LoadingScreen from "react-loading-screen";

import StyledWrapper from "./styled";

import { NoteForm, DefaultButton, InputField } from "../../components";
import validateForm from "../../utils/validateForm";
import bookingFeeValue from "../../utils/bookingFeeValue";
import { Sendmail } from "../../utils/agent";
const { confirm } = Modal;

function BookingConfirmation(props) {
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [fetchData, setFetchData] = useState();
  const [valueFirstname, setValueFirstname] = useState("");
  const [valueLastname, setValueLastname] = useState("");
  const [valueCountryCode, setValueCountryCode] = useState("+66");
  const [valuePhone, setValuePhone] = useState("");
  const [valueCustomerRemark, setValueCustomerRemark] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [isPaidSuccess, setIsPaidSuccess] = useState(null);
  const [isCreatedPayment, setIsCreatedPayment] = useState();

  const searchParam = queryString.parse(props.location.search);
  const orderId = _.get(searchParam, "orderId");

  useEffect(() => {
    _.isEmpty(props.location.search) &&
      window.setTimeout(() => {
        setIsLoadingScreen(false);
      }, 1000);

    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_HOST_PORT}/orders/${orderId}`
        );
        const data = result.data.result;
        setFetchData(data);
        setValueFirstname(_.get(data, "customerFirstname"));
        setValueLastname(_.get(data, "customerLastname"));
        setValuePhone(
          _.get(data, "customerCountryCode") === "+66"
            ? _.replace(_.get(data, "customerMobileNumber"), "0", "+66")
            : `${_.get(data, "customerCountryCode")}${_.get(
                data,
                "customerMobileNumber"
              )}`
        );
        setValueCustomerRemark(
          _.get(data, "customerRemark") !== "null"
            ? _.get(data, "customerRemark")
            : ""
        );
      } catch (error) {
        console.log("fetch error", { error });
      }
      setIsLoadingScreen(false);
    };
    fetchData();
  }, []);

  const objective =
    _.get(fetchData, "customerObjective") === 2
      ? "Want to rent"
      : "Want to buy";
  const bookingFee = bookingFeeValue(_.get(fetchData, "bookingFee"));
  const itemCode = _.get(fetchData, "itemCode");

  const fields = {
    saleCode: {
      type: "normal",
      label: "Sales code",
      value: _.get(fetchData, "saleCode"),
      disabled: true
    },
    itemCode: {
      type: "normal",
      label: "Item code",
      value: itemCode,
      disabled: true
    },
    projectName: {
      type: "normal",
      label: "Project name",
      value: _.get(fetchData, "projectName"),
      disabled: true
    },
    itemUnitNo: {
      type: "normal",
      label: "Item unit number",
      value: _.get(fetchData, "itemUnitNumber"),
      disabled: true
    },
    itemFloor: {
      type: "normal",
      label: "Floor",
      value: _.get(fetchData, "itemFloor"),
      disabled: true
    },
    ItemHomeNo: {
      type: "normal",
      label: "House number",
      value: _.get(fetchData, "itemHouseNumber"),
      disabled: true
    },
    CustomerObjective: {
      type: "normal",
      label: "Customer objective",
      value: objective,
      disabled: true
    },
    BookingFee: {
      type: "normal",
      label: "Booking fee",
      value: bookingFee,
      disabled: true
    },
    Firstname: {
      type: "normal",
      label: "Firstname",
      value: valueFirstname,
      rules: [{ required: true, message: "Please input your firstname!" }]
    },
    Lastname: {
      type: "normal",
      label: "Lastname",
      value: valueLastname,
      rules: [{ required: true, message: "Please input your lastname!" }]
    },
    Mobile: {
      type: "phone",
      label: "Mobile",
      value: valuePhone,
      rules: [{ required: true, message: "Please input your mobile!" }]
    },
    Email: {
      type: "email",
      label: "Email",
      value: _.get(fetchData, "customerEmail"),
      disabled: true
    },
    SalesRemark: {
      type: "normal",
      label: "Sales Remark",
      value: _.get(fetchData, "salesRemark"),
      disabled: true
    },
    CustomerRemark: {
      type: "multiline",
      label: "Customer Remark",
      value: valueCustomerRemark
    }
  };

  const bodyUpdate = {
    Headers: {
      "Content-Type": "application/json"
    },
    order_id: orderId,
    firstname: valueFirstname,
    lastname: valueLastname,
    country_code: valueCountryCode,
    phone_no: _.startsWith(valuePhone, "+66")
      ? _.replace(valuePhone, "+66", "0")
      : valuePhone,
    customer_remark: valueCustomerRemark
  };

  const bodyPayment = {
    Headers: {
      "Content-Type": "application/json"
    },
    orderId: orderId,
    orderRef: "838174 (Mock)",
    prc: 12345,
    src: 12345,
    ord: 12345,
    holder: "xxxxx",
    successcode: 1,
    payRef: 12341234,
    amt: _.toNumber(_.get(fetchData, "bookingFee")),
    cur: "THB",
    remark: "xxxxx",
    authId: "xxxxx",
    eci: "05",
    payerAuth: "Y",
    sourceIp: "192.168.1.23",
    orderRef1: "qwerrt"
  };

  const onValueChange = (e, name) => {
    if (name === "Firstname") {
      setValueFirstname(e.target.value);
    }
    if (name === "Lastname") {
      setValueLastname(e.target.value);
    }
    if (name === "Customer Remark") {
      setValueCustomerRemark(e.target.value);
    }
  };

  const handlePhoneNumber = (value, data) => {
    setValueCountryCode(`+${_.get(data, "dialCode")}`);
    setValuePhone(_.replace(value, /[^0-9]+/g, "").slice(data.dialCode.length));
  };

  const renderField = field => {
    const { status, message } = validateForm(
      _.get(field, "value"),
      _.get(field, "rules")
    );
    return (
      <InputField
        label={_.get(field, "label")}
        type={_.get(field, "type")}
        message={message}
        value={_.get(field, "value")}
        onChange={
          _.get(field, "type") === "phone"
            ? handlePhoneNumber
            : e => onValueChange(e, _.get(field, "label"))
        }
        isValidate={isValidate}
        disabled={_.get(field, "disabled")}
      />
    );
  };

  const handleUpdate = mockPaidStatus => {
    setIsValidate(true);
    if (
      !_.isEmpty(valueFirstname) &&
      !_.isEmpty(valueLastname) &&
      !_.isEmpty(valuePhone)
    ) {
      confirm({
        title: "Do you want to confirm this information?",
        // content: 'After you confirm, it will link to payment page',
        onOk() {
          window.setTimeout(() => {
            window.scrollTo({ top: 9999, behavior: "smooth" });
          }, 100);
          const updateData = async () => {
            try {
              const results = await axios.put(
                `${process.env.REACT_APP_HOST_PORT}/orders/update`,
                bodyUpdate
              );
              // console.log('search/bykiosk:: ', results);
              return results.data.message;
            } catch (error) {
              console.log("update error", { error });
            }
          };
          updateData();
          handlePayment(mockPaidStatus);
        }
      });
    }
  };

  const createPayment = async () => {
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_HOST_PORT}/payment/create`,
        bodyPayment
      );
      // console.log("search/bykiosk:: ", results.data.message);
      setIsCreatedPayment(results.data.message);
      const mailBody = {
        "project-name": _.get(fetchData, "projectName"),
        "mail-to": _.get(fetchData, "customerEmail"),
        "url-payment": `https://the-agent-payment-gateway.netlify.com/booking-confirmation/?orderId=${orderId}`
      };
      if (
        results.data.status === "success" &&
        !_.isEmpty(_.get(fetchData, "customerEmail"))
      ) {
        Sendmail(`${process.env.REACT_APP_HOST_PORT}/sendmail`, mailBody);
      }
    } catch (error) {
      console.log("payment error", { error });
    }
  };

  const handlePayment = mockPaidStatus => {
    setIsPaidSuccess(mockPaidStatus);
    mockPaidStatus === true ? createPayment() : setIsPaidSuccess(false);
    // console.log("isPaidSuccess ", isPaidSuccess);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isPaidSuccess) {
      if (isCreatedPayment === "createPayment") {
        props.history.push(`/payment-successful/?orderId=${orderId}`);
      } else {
        e.preventDefault();
      }
    } else {
      Modal.info({
        title: "Do you want to delete these items?",
        content:
          "When clicked the OK button, this dialog will be closed and redirect to The Agent website.",
        onOk() {
          const deleteOrder = async () => {
            try {
              const results = await axios.delete(
                `${process.env.REACT_APP_HOST_PORT}/deleteorders/${orderId}`
              );
            } catch (error) {
              console.log("delete error", { error });
            }
          };
          deleteOrder();
          window.location.replace(
            `https://www.theagent.co.th/property/d/${itemCode}`
          );
        }
      });
    }
  };

  return (
    <LoadingScreen
      loading={isLoadingScreen}
      bgColor="#f5f5f5"
      spinnerColor="#EC6219"
    >
      <StyledWrapper isPaidSuccess={isPaidSuccess}>
        {_.isUndefined(orderId) ||
        _.isUndefined(fetchData) ||
        _.isEmpty(orderId) ? (
          <div className="token-error">
            <Result
              status="error"
              title="Permission Denied"
              subTitle="Please make sure you have the correct access login as user and try again."
            />
          </div>
        ) : (
          <div className="booking-confirmation-wrapper">
            <h1 className="page-name">Booking Confirmation</h1>
            <Form onSubmit={handleSubmit} className="form-wrapper">
              {/* {!_.isNull(isPaidSuccess) && (
                <React.Fragment>
                  <NoteForm text={`${isPaidSuccess ? `ชำระเงินเรียบร้อยแล้ว\nท่านจะได้รับ email ยืนยันการชำระเงินจากทาง the agent\nภายใน 24 ชั่วโมง` : `ท่านยังไม่ได้ทำรายการชำระเงิน`}`} />
                  <div className="button-wrapper-center">
                    <DefaultButton text={`${isPaidSuccess ? 'View Order' : 'Cancel Order'}`} htmlType="submit" />
                  </div>
                </React.Fragment>
              )} */}
              <Row
                gutter={24}
                className="row-detail"
                type="flex"
                justify="center"
                align="top"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Sales code</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "saleCode", "Fern Mock 111"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Item code</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "itemCode"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Project name</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "projectName"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Item unit number</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "itemUnitNo"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Floor</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "itemFloor"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>House number</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "ItemHomeNo"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Customer Objective*</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "CustomerObjective"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Booking fee*</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "BookingFee"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Firstname*</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "Firstname"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Lastname*</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "Lastname"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Mobile*</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "Mobile"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Email</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "Email"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Sales Remark</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "SalesRemark"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Customer Remark</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  {renderField(_.get(fields, "CustomerRemark"))}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={4}
                  className="margin-bottom-15"
                >
                  <label>Payment Method</label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                  <div className="payment-button-wrapper">
                    <Button
                      type="default"
                      className="payment-button"
                      onClick={() => handleUpdate(false)}
                    >
                      Online Payment
                    </Button>
                    <Button
                      type="default"
                      className="payment-button"
                      onClick={() => handleUpdate(true)}
                    >
                      Pay by credit card
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="note-button-wrapper">
                <NoteForm
                  text={`${
                    isPaidSuccess
                      ? `ชำระเงินเรียบร้อยแล้ว\nท่านจะได้รับ email ยืนยันการชำระเงินจากทาง the agent\nภายใน 24 ชั่วโมง`
                      : `ท่านยังไม่ได้ทำรายการชำระเงิน`
                  }`}
                />
                <div className="button-wrapper-center">
                  <DefaultButton
                    text={`${isPaidSuccess ? "View Order" : "Cancel Order"}`}
                    htmlType="submit"
                  />
                </div>
              </div>
              <div
                id="anchor-name"
                style={{ position: "absolute", bottom: "-200px" }}
              ></div>
            </Form>
          </div>
        )}
      </StyledWrapper>
    </LoadingScreen>
  );
}

export default BookingConfirmation;
