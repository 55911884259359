import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDct2OzHAhgGwWsu4cfcMnXo2AtzJQM3cw",
  authDomain: "mi-treasure.firebaseapp.com",
  databaseURL: "https://mi-treasure.firebaseio.com",
  projectId: "mi-treasure",
  storageBucket: "mi-treasure.appspot.com",
};

firebase.initializeApp(firebaseConfig);

export default firebase;