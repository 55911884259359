import React from "react";
import { Select } from "antd";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import Styled from "./styled";

const { Option } = Select;

const renderOptions = opt => {
  const options = _.map(opt, (option, key) => {
    return (
      <Option value={_.get(option, "objectiveCode")} key={key}>
        {_.upperFirst(_.lowerCase(_.get(option, "text")))}
      </Option>
    );
  });
  return options;
};

function inputField(props) {
  const {
    type,
    label,
    value,
    onChange,
    message,
    options,
    disabled,
    onBlur,
    onFocus,
    isValidate
  } = props;
  const renderInputField = type => {
    switch (type) {
      case "normal":
        return (
          <React.Fragment>
            <input
              className="main-input"
              type="text"
              value={value}
              onChange={onChange}
              disabled={disabled}
              onBlur={onBlur}
              onFocus={onFocus}
            />
            {isValidate && <span className="error-meassage">{message}</span>}
          </React.Fragment>
        );
      case "email":
        return (
          <React.Fragment>
            <input
              className="main-input"
              type="email"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
            {isValidate && <span className="error-meassage">{message}</span>}
          </React.Fragment>
        );
      case "checkbox":
        return (
          <React.Fragment>
            <input
              className="main-input"
              type="text"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
            {isValidate && <span className="error-meassage">{message}</span>}
          </React.Fragment>
        );
      case "select":
        return (
          <React.Fragment>
            <Select
              defaultValue={value}
              value={value}
              className="select-field"
              id="select-field"
              onChange={onChange}
            >
              {renderOptions(options, value)}
            </Select>
            {isValidate && <span className="error-meassage">{message}</span>}
          </React.Fragment>
        );
      case "phone":
        return (
          <React.Fragment>
            <PhoneInput
              defaultCountry={"th"}
              value={value}
              onChange={onChange}
              countryCodeEditable={false}
            />
            {isValidate && <span className="error-meassage">{message}</span>}
          </React.Fragment>
        );
      case "multiline":
        return (
          <React.Fragment>
            <textarea
              className="main-input text-area"
              value={value}
              onChange={onChange}
              rows="5"
            />
            {isValidate && <span className="error-meassage">{message}</span>}
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return <Styled disabled={disabled}>{renderInputField(type)}</Styled>;
}

export default inputField;
