import styled from "styled-components";

export default styled.div`
  .booking-confirmation-wrapper {
    position: relative;
    /* padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left; */
    .confirmation-form {
      width: 100%;
    }
    .margin-bottom-15 {
      margin-bottom: 15px;
    }
    .form-wrapper {
      margin-top: 20px;
    }
    .row-detail {
      margin-top: 20px;
    }
    .payment-button {
      margin-right: 10px;
      border-radius: 0;
      background-color: #fff;
      color: #000;
      &:hover,
      &:focus,
      &:active {
        border-color: #d9d9d9;
        background-color: #d9d9d9;
      }
    }
    .note-button-wrapper {
      opacity: ${props =>
        props.isPaidSuccess === true
          ? 1
          : props.isPaidSuccess === false
          ? 1
          : 0};
      transition: all 0.4s;
      .note-wrapper {
        display: ${props =>
          props.isPaidSuccess === true
            ? "block"
            : props.isPaidSuccess === false
            ? "block"
            : "none"};
      }
      .form-button {
        display: ${props =>
          props.isPaidSuccess === true
            ? "flex"
            : props.isPaidSuccess === false
            ? "flex"
            : "none"};
      }
    }
    @media only screen and (max-width: 1199px) {
      .payment-button-wrapper {
        display: flex;
        flex-direction: column;
        .payment-button {
          margin-right: 0;
          margin-bottom: 10px;
          text-align: left;
        }
      }
    }
  }
`;
