import _ from 'lodash';

export default (value, rules) => {
  if (_.isEmpty(rules)) return { status: 'default', message: '' };
    for (let i = 0; i < rules.length; i += 1) {
      const rule = rules[i];
      if (_.isEmpty(value) && rule.required === true) {
        return {
          status: 'error',
          message: rule.message,
        };
      }
      if (!_.isEmpty(value) && rule.regex && rule.regex.test(value) === false) {
        return {
          status: 'error',
          message: rule.message,
        };
      }
    }
  return {
    status: 'success',
    message: '',
  };
};