import styled from "styled-components";

export default styled.div`
  .token-error {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .booking-field-wrapper {
    text-align: left;
    min-height: 88vh;
    .margin-bottom-15 {
      margin-bottom: 15px;
    }
    .form-wrapper {
      margin-top: 20px;
    }
    .row-detail {
      margin-top: 20px;
    }
  }
`;
