import styled from 'styled-components';

export default styled.div`
  .payment-successful-wrapper {
    color: #000;
    .detail-wrapper {
      background-color: #fff;
      padding: 50px;
      margin-top: 20px;
    }
    .booking-ref {
      font-weight: bold;
      margin-top: 80px;
      margin-bottom: 50px;
    }
    .title-detail {
      color: #A2A2A2;
      text-align: right;
    }
    .note-wrapper {
      background-color: #494949;
      color: #fff;
      padding: 30px 20px;
    }
    .note-title {
      font-weight: bold;
    }
    .note-detail {
      font-weight: bold;
      text-align: right;
    }
    .margin-bottom-0 {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 768px) {
      .title-detail {
        text-align: left;
      }
      .note-wrapper {
        margin-top: 30px;
      }
    }
    @media only screen and (max-width: 576px) {
      .note-title {
        font-weight: normal;
      }
      .note-detail {
        text-align: left;
      }
      .margin-bottom-0 {
        margin-bottom: 10px;
      }
    }
  }
`;