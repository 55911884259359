import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import PT from 'prop-types';
import StyledWrapper from './styled';

class DefaultButton extends Component {
  static propTypes = {
    buttonClicked: PT.func
  };

  static defaultProps = {
    buttonClicked: () => null
  };

  render() {
    const { loading, htmlType, className, text, buttonClicked, copied, disabled } = this.props;
    return (
      <StyledWrapper loading={loading} copied={copied}>
        <Button htmlType={htmlType} className={`form-button ${className}`} onClick={buttonClicked} disabled={disabled}>
          <span className="button-text">{text}</span>
          {loading === "true" && (
            <Icon type="loading" />
          )}
        </Button>
        {copied && (
          <div className="copied-wrapper">
            <p className="copied-text">Already copied</p>
          </div>
        )}
      </StyledWrapper>
    );
  }
}

export default DefaultButton;
