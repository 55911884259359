import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Row, Col, Result } from "antd";
import queryString from "query-string";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import StyledWrapper from "./styled";

import bookingFeeValue from "../../utils/bookingFeeValue";

function PaymentSuccessful(props) {
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [fetchData, setFetchData] = useState();

  const searchParam = queryString.parse(props.location.search);
  const orderId = _.get(searchParam, "orderId");

  useEffect(() => {
    _.isEmpty(props.location.search) &&
      window.setTimeout(() => {
        setIsLoadingScreen(false);
      }, 1000);

    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_HOST_PORT}/orders/${orderId}`
        );
        setFetchData(result.data.result);
      } catch (error) {
        console.log("fetch error", { error });
      }
      setIsLoadingScreen(false);
    };
    fetchData();
  }, []);

  const objective =
    _.get(fetchData, "customerObjective") === "2"
      ? "Want to sell"
      : "Want to buy";
  const bookingFee = bookingFeeValue(_.get(fetchData, "bookingFee"));

  return (
    <LoadingScreen
      loading={isLoadingScreen}
      bgColor="#f5f5f5"
      spinnerColor="#EC6219"
    >
      <StyledWrapper>
        {_.isUndefined(orderId) ||
        _.isUndefined(fetchData) ||
        _.isEmpty(orderId) ? (
          <div className="token-error">
            <Result
              status="error"
              title="Permission Denied"
              subTitle="Please make sure you have the correct access login as user and try again."
            />
          </div>
        ) : (
          <div className="payment-successful-wrapper">
            <h1 className="page-name">Payment Successful</h1>
            <div className="detail-wrapper">
              <p>
                Payment has been processed. We've sent the payment confirmation
                to emails (agent and customer) Please see the information below.{" "}
              </p>
              <p className="booking-ref">{`Booking Reference: ${orderId}`}</p>
              <Row type="flex" align="middle">
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Row gutter={24}>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Item code</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">{_.get(fetchData, "itemCode")}</p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Project name</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "projectName") === "" ||
                        _.get(fetchData, "projectName") === "null"
                          ? "-"
                          : _.get(fetchData, "projectName")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Item unit number</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "itemUnitNumber") === "" ||
                        _.get(fetchData, "itemUnitNumber") === "null"
                          ? "-"
                          : _.get(fetchData, "itemUnitNumber")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Floor</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "itemFloor") === "" ||
                        _.get(fetchData, "itemFloor") === "null"
                          ? "-"
                          : _.get(fetchData, "itemFloor")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">House number</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "itemHouseNumber") === "" ||
                        _.get(fetchData, "itemHouseNumber") === "null"
                          ? "-"
                          : _.get(fetchData, "itemHouseNumber")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Customer objective</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">{objective}</p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Booking fee</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">{bookingFee}</p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Firstname</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "customerFirstname")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Lastname</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "customerLastname")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Mobile</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "customerCountryCode") === "+66"
                          ? `${_.get(fetchData, "customerMobileNumber")}`
                          : `${_.get(fetchData, "customerCountryCode")}${_.get(
                              fetchData,
                              "customerMobileNumber"
                            )}`}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Email</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "customerEmail") === "" ||
                        _.get(fetchData, "customerEmail") === "null"
                          ? "-"
                          : _.get(fetchData, "customerEmail")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Sales remark</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "salesRemark") === "" ||
                        _.get(fetchData, "salesRemark") === "null"
                          ? "-"
                          : _.get(fetchData, "salesRemark")}
                      </p>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                      <p className="title-detail">Customer remark</p>
                    </Col>
                    <Col xs={24} sm={12} md={16} lg={12} xl={12}>
                      <p className="detail">
                        {_.get(fetchData, "customerRemark") === "" ||
                        _.isEmpty(_.get(fetchData, "customerRemark"))
                          ? "-"
                          : _.get(fetchData, "customerRemark")}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className="note-wrapper">
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p className="note-title">Sales code</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p className="note-detail">Agent 001 (Mock)</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p className="note-title margin-bottom-0">
                          Booking fee
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p className="note-detail margin-bottom-0">{`${bookingFee} THB`}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </StyledWrapper>
    </LoadingScreen>
  );
}

export default PaymentSuccessful;
