import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import _ from "lodash";
import { Row, Col } from "antd";
import firebase from "./config/firebase";

import routes from "./route";
import "./App.css";
import "antd/dist/antd.css";
import "react-phone-input-2/dist/style.css";

const renderRoutes = () => {
  const page = _.map(routes, (route, i) => {
    if (route.to === "/") {
      return (
        <Route
          key={i}
          path="/"
          exact={route.exact}
          render={() => <Redirect to="/booking-enquiry" />}
        />
      );
    } else {
      return (
        <Route
          key={i}
          path={route.to}
          exact={route.exact}
          component={route.component}
        />
      );
    }
  });
  return page;
};

function App(props) {
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      // alert(user)
      // console.log('user', user);
      if (user) {
        alert(user);
      }
    });
  }, []);

  return (
    <div className="page-wrapper">
      <Router basename="/">
        <Row className="page-grid">
          <Col
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 14, offset: 5 }}
          >
            {renderRoutes()}
          </Col>
          {/* )} */}
        </Row>
      </Router>
    </div>
  );
}

export default App;
