import styled from 'styled-components';

export default styled.div`
  position: relative;
  .form-button {
    background-color: #EC6219;
    border-color: transparent;
    border-radius: 0;
    color: #fff;
    width: 150px;
    margin-top: 20px;
    height: 40px;
    padding: ${props => props.loading ? '25px 0' : '0'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .button-text {
      text-transform: uppercase;
      margin-bottom: ${props => props.loading ? '5px' : '0'};
    }
    &:hover,
    &:active,
    &:focus {
      background-color: #EC6219;
      border-color: transparent !important;
      color: #fff !important;
    }
  }
  .copied-wrapper {
    background-color: #fff;
    text-align: center;
    position: absolute;
    width: 150px;
    padding: 2px 0;
    bottom: -20px;
    border-bottom: 2px solid #8F8F8F;
    .copied-text {
      margin-bottom: 0;
    }
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus {
    background-color: rgba(236, 98, 25, 0.25) !important;
    color: rgba(255, 255, 255, 0.25) !important;
    border-color: transparent !important;
  }
`;
